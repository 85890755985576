// 滑鼠右鍵
//document.oncontextmenu=function(e){return false;}

// 決解IOS無法 Hover
document.body.addEventListener('touchstart', function () {}, false);
// 決解FontAwesome ICON 存問題
document.addEventListener('turbolinks:load', function () {
    FontAwesome.dom.i2svg()
})
//=====footer置底自動計算RWD可用=====
$(document).ready(function () {
    $(window).resize(function () {
        var footerHeight = $('footer').outerHeight();
        var stickFooterPush = $('.push').height(footerHeight);
        $('#contents').css({
            'marginBottom': '-' + footerHeight + 'px'
        });
    });
    $(window).resize();
});


// ===== Scroll to Top 向上案鈕====
$(window).scroll(function () {
    if ($(this).scrollTop() >= 400) {
        $('#top').fadeIn("fast");
    } else {
        $('#top').fadeOut("fast");
    }
});
$('#top').click(function () {
    $('body,html').animate({
        scrollTop: 0
    }, 600);
});


/*!
* Bootstrap 4 dropdown navbar 多層選單 ( https://bootstrapthemes.co/demo/resource/bootstrap-4-multi-dropdown-navbar/ )
*
*/
$(document).ready(function () {
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
        var $el = $(this);
        var $parent = $(this).offsetParent(".dropdown-menu");
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');
        $(this).parent("li").toggleClass('show');
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-menu .show').removeClass("show");
        });
        if (!$parent.parent().hasClass('navbar-nav')) {
            $el.next().css({
                "top": $el[0].offsetTop,
                "left": $parent.outerWidth() - 4
            });
        }
        return false;
    });
});
/*!
* Bootstrap 4動畫漢堡包導航員
* Bootstrap 4 animated hamburger nav - toggler
*
*/
$(function () {
    //toggle class open on button
    $('#navbarNavDropdown').on('hide.bs.collapse', function () {
        $('.navbar-toggler').removeClass('open');
    })
    $('#navbarNavDropdown').on('show.bs.collapse', function () {
        $('.navbar-toggler').addClass('open');
    })
});
 